import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { startOfMonth, endOfMonth, differenceInDays } from 'date-fns';
import { RiCreativeCommonsLine, RiCreativeCommonsByLine, RiCreativeCommonsNcLine, RiCreativeCommonsSaLine } from 'react-icons/ri';

import { ReactComponent as Logo } from './../assets/img/logo.svg';

class App extends Component{
  constructor(){
    super();
    
    this.state = {
      unitDay: 0,
      unitQuarter: 0,
      unitSemester: 0,
      progress: {
        mili: {
          value: 0,
          percentage: 0
        },
        second: {
          value: 0,
          percentage: 0
        },
        minute: {
          value: 0,
          percentage: 0
        },
        hour: {
          value: 0,
          percentage: 0
        },
        day: {
          value: 0,
          percentage: 0
        },
        month: {
          value: 0,
          percentage: 0
        },
        quarter: {
          value: 0,
          percentage: 0
        },
        semester: {
          value: 0,
          percentage: 0
        }
      }
    }

    this.copy = 2024;
    this.today = new Date();
    this.interval = '';

    this.unitMili = 1000;
    this.unitSecond = 60;
    this.unitHour = 24;
    this.unitMonth = 12;
    
    this.getTime = this.getTime.bind(this);
  }

  componentDidMount(){
    this.getTime();

    this.interval = setInterval(() => this.getTime(), 1);
  }

  componentDidUpdate(prevProps, prevState){}

  componentWillUnmount(){
    clearInterval(this.interval);
  }

  getTime(){
    const newDate = new Date(),
          valMili = newDate.getMilliseconds(),
          perMili = valMili / 1000,
          valSec = newDate.getSeconds(),
          perSec = ((valSec + perMili) / 60),
          valMin = newDate.getMinutes(),
          perMin = ((valMin + perSec) / 60),
          valHour = newDate.getHours(),
          perHour = (valHour + perMin) / 24,
          valDay = newDate.getDate() - 1,
          startMonth = startOfMonth(newDate),
          endMonth = endOfMonth(newDate),
          dayMonth = differenceInDays(endMonth, startMonth) + 1,
          perDay = (valDay + perHour) / dayMonth,
          valMonth = newDate.getMonth(),
          perMonth = (valMonth + perDay) / 12,
          valQuarter = Math.floor((valMonth + 3) / 3),
          startQuarter = startOfMonth(new Date(newDate.getFullYear(), valQuarter === 1 ? 0 : valQuarter === 2 ? 3 : valQuarter === 3 ? 6 : 9)),
          endQuarter = endOfMonth(new Date(newDate.getFullYear(), valQuarter === 1 ? 2 : valQuarter === 2 ? 5 : valQuarter === 3 ? 8 : 11)),
          dayQuarter = differenceInDays(endQuarter, startQuarter) + 1,
          nowQuarter = differenceInDays(newDate, startQuarter) + 1,
          perQuarter = (nowQuarter + perDay) / dayQuarter,
          valSemester = Math.floor((valMonth + 6) / 6),
          startSemester = startOfMonth(new Date(newDate.getFullYear(), valQuarter === 1 ? 0 : 6)),
          endSemester = endOfMonth(new Date(newDate.getFullYear(), valQuarter === 1 ? 5 : 11)),
          daySemester = differenceInDays(endSemester, startSemester) + 1,
          nowSemester = differenceInDays(newDate, startSemester) + 1,
          perSemester = (nowSemester + perDay) / daySemester;

    // console.log(newDate, valMili, perMili, valSec, perSec, valMin, perMin, valHour, perHour, valDay, perDay, valMonth, perMonth, valQuarter, perQuarter, valSemester, perSemester);
          
    if(this.state.unitDay !== dayMonth){
      this.setState({ unitDay: dayMonth });
    }

    if(this.state.unitQuarter !== dayMonth){
      this.setState({ unitQuarter: dayQuarter });
    }

    if(this.state.unitSemester !== dayMonth){
      this.setState({ unitSemester: daySemester });
    }

    this.setState({
      progress: {
        mili: {
          value: valMili,
          percentage: perMili * 100
        },
        second: {
          value: valSec,
          percentage: perSec * 100
        },
        minute: {
          value: valMin,
          percentage: perMin * 100
        },
        hour: {
          value: valHour,
          percentage: perHour * 100
        },
        day: {
          value: valDay,
          percentage: perDay * 100
        },
        month: {
          value: valMonth,
          percentage: perMonth * 100
        },
        quarter: {
          value: valQuarter,
          percentage: perQuarter * 100
        },
        semester: {
          value: valSemester,
          percentage: perSemester * 100
        }
      }
    });
  }

  renderSection(name, calculated, value, unit, plus){
    const realValue = plus ? value.value + 1 : value.value,
          minValue = unit - realValue;

    return(
      <div>
        <ul>
          <li>
            {name}
          </li>
          <li>
            <strong>{minValue}</strong> {name === 'Quarter' || name === 'Semester' ? 'day' : calculated}{minValue > 1 ? 's' : ''} to go
          </li>
          <li>
            {calculated} <strong>{realValue}</strong> 
          </li>
        </ul>
         
        <div>
          <span>{parseFloat((value.percentage).toFixed(2))}%</span>
          <div style={{width: value.percentage + '%'}}></div>
        </div>
      </div>
    );
  }
  
  render(){
    const progress = this.state.progress;

    return (
      <>
        <Helmet>
          <title>TimeProgress {(parseFloat((progress.month.percentage).toFixed(2))).toString()}%</title>
        </Helmet>

        <header>
          <a href="/">
            <Logo />
          </a>
        </header>

        <div className="pad-top"></div>

        <ul className="the-progress">
          <li>
            {this.renderSection('Second', 'milisecond', progress.mili, this.unitMili, false)}
          </li>
          <li>
            {this.renderSection('Minute', 'second', progress.second, this.unitSecond, false)}
          </li>
          <li>
            {this.renderSection('Hour', 'minute', progress.minute, this.unitSecond, false)}
          </li>
          <li>
            {this.renderSection('Day', 'hour', progress.hour, this.unitHour, false)}
          </li>
          <li>
            {this.renderSection('Month', 'day', progress.day, this.state.unitDay, true)}
          </li>
          <li>
            {this.renderSection('Year', 'month', progress.month, this.unitMonth, true)}
          </li>
          <li>
            {this.renderSection('Quarter', 'quarter', progress.quarter, this.state.unitQuarter, false)}
          </li>
          <li>
            {this.renderSection('Semester', 'semester', progress.semester, this.state.unitSemester, false)}
          </li>
        </ul>

        <footer>
          <a href="/">TimeProgress</a> &copy; {this.today.getFullYear() !== this.copy ? this.copy + ' - ' + this.today.getFullYear() : this.copy} by <a href="https://nabilamerthabit.com" target="_blank" rel="noreferrer">Nabil Amer Thabit</a> is licensed under <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/?ref=chooser-v1" target="_blank" rel="license noopener noreferrer">CreativeCommons BY-NC-SA 4.0 <RiCreativeCommonsLine /> <RiCreativeCommonsByLine /> <RiCreativeCommonsNcLine /> <RiCreativeCommonsSaLine /></a>
        </footer>
      </>
    );
  }
}

export default App;